import React from "react";

export const Footer = (props: any) => {
  return (
    <div className={props.className}>
      <p>contact@phoenix-mc.org</p>
      <p>(800) 555-1234</p>
    </div>
  );
};
